import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { notifications } from 'src/constants';
import { player } from 'src/utils';
import { useIntlMessages } from 'src/hooks';

import { OverlayLoader, Student } from 'src/components/common';

import { AddStudent } from './components';

import './index.scss';

const PackageStudents = ({
  addToUser,
  getStudents,
  hasLoaded,
  resolveUsers,
  showNotification,
  user,
  userOnlyStudents,
  handleInviteUser,
}) => {
  const messages = useIntlMessages();

  const handleSubmitNewUser = useCallback(
    (data) => {
      addToUser(data).then(() => {
        getStudents();
        showNotification(
          messages.notification.studentAddedSuccessfully,
          notifications.type.SUCCESS,
          notifications.duration.SHORT
        );
      });
    },
    [addToUser, getStudents, messages, showNotification]
  );

  const handleSelectStudent = useCallback(
    (studentId, callback) => {
      player.setSelectedStudentId(studentId);
      resolveUsers().then(() => callback && callback());
    },
    [resolveUsers]
  );

  const unusedStudentAccounts = useMemo(
    () => user.studentAccountsCapacity - userOnlyStudents.length,
    [user, userOnlyStudents.length]
  );

  if (!hasLoaded) {
    return (
      <div className="package-students-container">
        <OverlayLoader className="package-students-container__overlay-loader" />
      </div>
    );
  }

  return (
    <div className="package-students-container">
      <div className="package-students-container__items">
        <AddStudent
          isDisabled={
            user.studentAccountsCapacity > 0 && unusedStudentAccounts <= 0
          }
          onSubmit={handleSubmitNewUser}
        />
        <div className="package-students-container__students-section">
          {userOnlyStudents.map((student, index) => (
            <Student
              key={student.id}
              student={student}
              isTestStudent={index === 0}
              handleInviteStudent={() => handleInviteUser(student)}
              handleOnClick={handleSelectStudent}
              userId={user.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

PackageStudents.propTypes = {
  addToUser: PropTypes.func.isRequired,
  getStudents: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  resolveUsers: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    studentAccountsCapacity: PropTypes.number.isRequired,
  }).isRequired,
  userOnlyStudents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleInviteUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: selectors.authentication.getUser(state),
  hasLoaded: selectors.students.getHasLoaded(state),
  userOnlyStudents: selectors.students.getUserOnlyStudents(state),
});

const mapDispatchToProps = {
  ...actions.authentication,
  ...actions.notifications,
  ...actions.students,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(PackageStudents);
