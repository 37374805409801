import React from 'react';
import { Localize } from '@kokolingo/localisation';
import { object, string } from 'yup';
import { languages } from 'src/constants';

export default object().shape({
  childName: string().required(() => <Localize id="error.childNameRequired" />),
  fullName: string().required(() => <Localize id="error.fullNameRequired" />),
  email: string()
    .email(() => <Localize id="error.emailFormatInvalid" />)
    .required(() => <Localize id="error.emailRequired" />),
  phoneNumber:
    process.env.REACT_APP_LANGUAGE === languages.enUs
      ? string()
      : string().required(() => <Localize id="error.phoneNumberRequired" />),
});
