import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { useIntlMessages } from 'src/hooks';
import { languages, paths } from 'src/constants';

import { Form, Input, PasswordInput } from 'src/components/common';

import validationSchema from './validationSchema';

import './index.scss';

const FormContainer = ({ defaultValues, onSubmit }) => {
  const messages = useIntlMessages();

  return (
    <div className="registration-form-container">
      <Form
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        submitBtnLabel={messages.button.register}
        defaultValues={defaultValues}
      >
        <Input
          label={messages.label.childName}
          name="studentFullName"
          required
        />
        <Input label={messages.label.parentName} name="fullName" required />
        <Input
          label={messages.label.email}
          name="email"
          required
          disabled={defaultValues.email}
          type="email"
        />
        <PasswordInput
          label={messages.label.password}
          name="password"
          required
        />
        <Input
          label={messages.label.phoneNumber}
          name="phoneNumber"
          required={process.env.REACT_APP_LANGUAGE !== languages.enUs}
        />
        {process.env.REACT_APP_LANGUAGE === languages.enUs && (
          <Input label={messages.label.state} name="state" />
        )}
        <Input label={messages.label.city} name="city" />
        <Input
          required
          label={messages.label.termsOfService}
          type="checkbox"
          name="gdpr"
        />
        <Input
          name="utmCampaign"
          type="hidden"
          defaultValue={defaultValues.utmCampaign}
        />
      </Form>
      <div className="registration-form-container__login-redirect">
        <span>
          {messages.label.haveAccount}{' '}
          <Link to={paths.LOGIN}>{messages.button.haveAccount}</Link>
        </span>
      </div>
    </div>
  );
};

FormContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    email: PropTypes.string,
    utmCampaign: PropTypes.string,
  }),
};

FormContainer.defaultProps = {
  defaultValues: {},
};

export default memo(FormContainer);
