import { actions } from 'src/constants';

const initialState = {
  data: [],
  hasLoaded: false,
  isLoading: false,
};

const currencyKeys = ['gold', 'orange', 'green', 'purple', 'red', 'blue'];

const actionMap = {
  [actions.WALLET_GET_REQUEST]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: true,
  }),
  [actions.WALLET_GET_SUCCESS]: (state, { result }) => ({
    ...state,
    data: result.data,
    hasLoaded: true,
    isLoading: false,
  }),
  [actions.WALLET_GET_FAILURE]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: false,
  }),
  [actions.RESOLVE_USERS_SUCCESS]: (state) => ({
    ...state,
    ...initialState,
  }),
  [actions.FEEDBACK_UPDATE_SUCCESS]: (state, { result }) => {
    const walletData = [...state.data];
    const currencyId = result.data.currencyId - 1;
    const walletDataIndex = walletData.findIndex(
      (walletItem) => walletItem.currency.key === currencyKeys[currencyId]
    );

    walletData[walletDataIndex].amount += 1;

    return {
      ...state,
      data: walletData,
      hasLoaded: true,
      isLoading: false,
    };
  },
  [actions.PARROT_PARTS_BUY_POST_SUCCESS]: (state, { result }) => {
    const walletData = [...state.data];
    const parrotPartCurrencies = result.data.ParrotPartCostsCurrencies;

    parrotPartCurrencies.forEach((item) => {
      const walletDataIndex = walletData.findIndex(
        (walletItem) => walletItem.currency.key === item.currency.key
      );

      walletData[walletDataIndex].amount -= item.amount;
    });

    return {
      ...state,
      data: walletData,
      hasLoaded: true,
      isLoading: false,
    };
  },
};

export default (state = initialState, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
