import { actions, paths } from 'src/constants';

export default {
  activateLicense: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.LICENSE_ACTIVATE_POST_REQUEST,
        actions.LICENSE_ACTIVATE_POST_SUCCESS,
        actions.LICENSE_ACTIVATE_POST_FAILURE,
      ],
      promise: (client) => client.post(paths.api.LICENSING, data),
    },
  }),

  getLicense: () => ({
    [actions.API_CALL]: {
      types: [
        actions.LICENSE_GET_REQUEST,
        actions.LICENSE_GET_SUCCESS,
        actions.LICENSE_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.api.LICENSING),
    },
  }),
};
