const ACTIVITY_BASE_URL = '/activity/:groupKey';
const SUBACTIVITY_BASE_URL = `${ACTIVITY_BASE_URL}/subactivity/:activityId`;
const DAILY_MAP_BASE_URL = '/daily-map';

export default {
  ACTIVITY: `${ACTIVITY_BASE_URL}`,
  ARTICULATION_GYMNASTICS: `${ACTIVITY_BASE_URL}/articulation-gymnastics`,
  ARTICULATION_GYMNASTICS_LEVELS: `${ACTIVITY_BASE_URL}/articulation-gymnastics/:collectionId`,
  BASE: '/',
  CLIMB_STEP_THREE: `${SUBACTIVITY_BASE_URL}/climb-step-three`,
  CLIMB_STEP_THREE_LEVELS: `${SUBACTIVITY_BASE_URL}/climb-step-three/:collectionId`,
  CLIMB_STEP_THREE_EXTRA: `${SUBACTIVITY_BASE_URL}/climb-step-three-extra`,
  CLIMB_STEP_THREE_EXTRA_LEVELS: `${SUBACTIVITY_BASE_URL}/climb-step-three-extra/:collectionId`,
  CLIMB_STEP_TWO: `${SUBACTIVITY_BASE_URL}/climb-step-two`,
  CLIMB_STEP_TWO_LEVELS: `${SUBACTIVITY_BASE_URL}/climb-step-two/:collectionId`,
  CLIMB_STEP_TWO_EXTRA: `${SUBACTIVITY_BASE_URL}/climb-step-two-extra`,
  CLIMB_STEP_TWO_EXTRA_LEVELS: `${SUBACTIVITY_BASE_URL}/climb-step-two-extra/:collectionId`,
  CLIMB_STEP_ONE: `${SUBACTIVITY_BASE_URL}/climb-step-one`,
  CLIMB_STEP_ONE_LEVELS: `${SUBACTIVITY_BASE_URL}/climb-step-one/:collectionId`,
  CLIMB_STEP_ONE_EXTRA: `${SUBACTIVITY_BASE_URL}/climb-step-one-extra`,
  CLIMB_STEP_ONE_EXTRA_LEVELS: `${SUBACTIVITY_BASE_URL}/climb-step-one-extra/:collectionId`,
  DAILY_MAP: `${DAILY_MAP_BASE_URL}`,
  DISCOVER_STEP_THREE: `${SUBACTIVITY_BASE_URL}/discover-step-three`,
  DISCOVER_STEP_THREE_LEVELS: `${SUBACTIVITY_BASE_URL}/discover-step-three/:collectionId`,
  DISCOVER_STEP_THREE_EXTRA: `${SUBACTIVITY_BASE_URL}/discover-step-three-extra`,
  DISCOVER_STEP_THREE_EXTRA_LEVELS: `${SUBACTIVITY_BASE_URL}/discover-step-three-extra/:collectionId`,
  DISCOVER_STEP_TWO: `${SUBACTIVITY_BASE_URL}/discover-step-two`,
  DISCOVER_STEP_TWO_LEVELS: `${SUBACTIVITY_BASE_URL}/discover-step-two/:collectionId`,
  DISCOVER_STEP_TWO_EXTRA: `${SUBACTIVITY_BASE_URL}/discover-step-two-extra`,
  DISCOVER_STEP_TWO_EXTRA_LEVELS: `${SUBACTIVITY_BASE_URL}/discover-step-two-extra/:collectionId`,
  DISCOVER_STEP_ONE: `${SUBACTIVITY_BASE_URL}/discover-step-one`,
  DISCOVER_STEP_ONE_LEVELS: `${SUBACTIVITY_BASE_URL}/discover-step-one/:collectionId`,
  DISCOVER_STEP_ONE_EXTRA: `${SUBACTIVITY_BASE_URL}/discover-step-one-extra`,
  DISCOVER_STEP_ONE_EXTRA_LEVELS: `${SUBACTIVITY_BASE_URL}/discover-step-one-extra/:collectionId`,
  FIND_WORDS_STEP_THREE: `${SUBACTIVITY_BASE_URL}/find-words-step-three`,
  FIND_WORDS_STEP_THREE_LEVELS: `${SUBACTIVITY_BASE_URL}/find-words-step-three/:collectionId`,
  FIND_WORDS_STEP_THREE_EXTRA: `${SUBACTIVITY_BASE_URL}/find-words-step-three-extra`,
  FIND_WORDS_STEP_THREE_EXTRA_LEVELS: `${SUBACTIVITY_BASE_URL}/find-words-step-three-extra/:collectionId`,
  FIND_WORDS_STEP_TWO: `${SUBACTIVITY_BASE_URL}/find-words-step-two`,
  FIND_WORDS_STEP_TWO_LEVELS: `${SUBACTIVITY_BASE_URL}/find-words-step-two/:collectionId`,
  FIND_WORDS_STEP_TWO_EXTRA: `${SUBACTIVITY_BASE_URL}/find-words-step-two-extra`,
  FIND_WORDS_STEP_TWO_EXTRA_LEVELS: `${SUBACTIVITY_BASE_URL}/find-words-step-two-extra/:collectionId`,
  FIND_WORDS_STEP_ONE: `${SUBACTIVITY_BASE_URL}/find-words-step-one`,
  FIND_WORDS_STEP_ONE_LEVELS: `${SUBACTIVITY_BASE_URL}/find-words-step-one/:collectionId`,
  FIND_WORDS_STEP_ONE_EXTRA: `${SUBACTIVITY_BASE_URL}/find-words-step-one-extra`,
  FIND_WORDS_STEP_ONE_EXTRA_LEVELS: `${SUBACTIVITY_BASE_URL}/find-words-step-one-extra/:collectionId`,
  FLASH_CARDS_STEP_THREE: `${SUBACTIVITY_BASE_URL}/flash-cards-step-three`,
  FLASH_CARDS_STEP_THREE_LEVELS: `${SUBACTIVITY_BASE_URL}/flash-cards-step-three/:collectionId`,
  FLASH_CARDS_STEP_TWO: `${SUBACTIVITY_BASE_URL}/flash-cards-step-two`,
  FLASH_CARDS_STEP_TWO_LEVELS: `${SUBACTIVITY_BASE_URL}/flash-cards-step-two/:collectionId`,
  FLASH_CARDS_STEP_TWO_EXTRA: `${SUBACTIVITY_BASE_URL}/flash-cards-step-two-extra`,
  FLASH_CARDS_STEP_TWO_EXTRA_LEVELS: `${SUBACTIVITY_BASE_URL}/flash-cards-step-two-extra/:collectionId`,
  FLASH_CARDS_STEP_ONE: `${SUBACTIVITY_BASE_URL}/flash-cards-step-one`,
  FLASH_CARDS_STEP_ONE_LEVELS: `${SUBACTIVITY_BASE_URL}/flash-cards-step-one/:collectionId`,
  FLASH_CARDS_STEP_ONE_EXTRA: `${SUBACTIVITY_BASE_URL}/flash-cards-step-one-extra`,
  FLASH_CARDS_STEP_ONE_EXTRA_LEVELS: `${SUBACTIVITY_BASE_URL}/flash-cards-step-one-extra/:collectionId`,
  FLASH_CARDS_STEP_THREE_EXTRA: `${SUBACTIVITY_BASE_URL}/flash-cards-step-three-extra`,
  FLASH_CARDS_STEP_THREE_EXTRA_LEVELS: `${SUBACTIVITY_BASE_URL}/flash-cards-step-three-extra/:collectionId`,
  FORGOT_PASSWORD: '/forgot-password',
  HELP: '/help',
  LICENCE_STATUS: '/settings/licence-status',
  LICENSE: '/license',
  LOGIN: '/login',
  MAGIC_LINK: '/magic-link',
  MAP: '/map',
  MEMORY_STEP_THREE: `${SUBACTIVITY_BASE_URL}/memory-step-three`,
  MEMORY_STEP_THREE_COLLECTIONS: `${SUBACTIVITY_BASE_URL}/memory-step-three/:groupId`,
  MEMORY_STEP_THREE_LEVELS: `${SUBACTIVITY_BASE_URL}/memory-step-three/:groupId/collections/:collectionId`,
  MEMORY_STEP_THREE_EXTRA: `${SUBACTIVITY_BASE_URL}/memory-step-three-extra`,
  MEMORY_STEP_THREE_EXTRA_COLLECTIONS: `${SUBACTIVITY_BASE_URL}/memory-step-three-extra/:groupId`,
  MEMORY_STEP_THREE_EXTRA_LEVELS: `${SUBACTIVITY_BASE_URL}/memory-step-three-extra/:groupId/collections/:collectionId`,
  MEMORY_STEP_TWO: `${SUBACTIVITY_BASE_URL}/memory-step-two`,
  MEMORY_STEP_TWO_COLLECTIONS: `${SUBACTIVITY_BASE_URL}/memory-step-two/:groupId`,
  MEMORY_STEP_TWO_LEVELS: `${SUBACTIVITY_BASE_URL}/memory-step-two/:groupId/collections/:collectionId`,
  MEMORY_STEP_TWO_EXTRA: `${SUBACTIVITY_BASE_URL}/memory-step-two-extra`,
  MEMORY_STEP_TWO_EXTRA_COLLECTIONS: `${SUBACTIVITY_BASE_URL}/memory-step-two-extra/:groupId`,
  MEMORY_STEP_TWO_EXTRA_LEVELS: `${SUBACTIVITY_BASE_URL}/memory-step-two-extra/:groupId/collections/:collectionId`,
  MEMORY_STEP_ONE: `${SUBACTIVITY_BASE_URL}/memory-step-one`,
  MEMORY_STEP_ONE_COLLECTIONS: `${SUBACTIVITY_BASE_URL}/memory-step-one/:groupId`,
  MEMORY_STEP_ONE_LEVELS: `${SUBACTIVITY_BASE_URL}/memory-step-one/:groupId/collections/:collectionId`,
  MEMORY_STEP_ONE_EXTRA: `${SUBACTIVITY_BASE_URL}/memory-step-one-extra`,
  MEMORY_STEP_ONE_EXTRA_COLLECTIONS: `${SUBACTIVITY_BASE_URL}/memory-step-one-extra/:groupId`,
  MEMORY_STEP_ONE_EXTRA_LEVELS: `${SUBACTIVITY_BASE_URL}/memory-step-one-extra/:groupId/collections/:collectionId`,
  FORBIDDEN: '/forbidden-page',
  PAYMENT_BASE: '/payment',
  PAYMENT_ROUTER: '/payment/:paymentProvider?',
  PAINTING: `${SUBACTIVITY_BASE_URL}/painting`,
  PAINTING_COLLECTIONS: `${SUBACTIVITY_BASE_URL}/painting/:groupId`,
  PAINTING_LEVELS: `${SUBACTIVITY_BASE_URL}/painting/:groupId/collections/:collectionId`,
  READ_AND_THINK: `${ACTIVITY_BASE_URL}/read-and-think`,
  READ_AND_THINK_BONUS_LEVELS: `${ACTIVITY_BASE_URL}/read-and-think/:collectionId/bonus-levels`,
  READ_AND_THINK_LEVELS: `${ACTIVITY_BASE_URL}/read-and-think/:collectionId`,
  READ_TOGETHER: `${ACTIVITY_BASE_URL}/read-together`,
  READ_TOGETHER_BONUS_LEVELS: `${ACTIVITY_BASE_URL}/read-together/:collectionId/bonus-levels`,
  READ_TOGETHER_LEVELS: `${ACTIVITY_BASE_URL}/read-together/:collectionId`,
  REGISTER_CLIENT: '/register/client',
  REPLENISHMENT: `${ACTIVITY_BASE_URL}/replenishment`,
  REPLENISHMENT_LEVELS: `${ACTIVITY_BASE_URL}/replenishment/:collectionId`,
  REGISTER_SPEECH_THERAPIST: '/register/speech-therapist',
  RESET_PASSWORD: '/reset-password',
  SETTINGS: '/settings',
  SETTINGS_COUPON: '/settings/coupon',
  SETTINGS_DAILY_MAP: '/settings/daily-map',
  SETTINGS_SOUND: '/settings/sound',
  SETTINGS_STUDENT: '/settings/student/:studentId',
  SETTINGS_USER: '/settings/user',
  SHOP: '/shop',
  SPEECH_SOUND_SELECTION: '/speech-sounds',
  SPEECH_THERAPIST: '/speech-therapist',
  STATISTICS: '/statistics',
  SUBACTIVITY: `${SUBACTIVITY_BASE_URL}`,
  SUBSCRIPTIONS: '/settings/subscriptions',
  SUBSCRIPTION_PAYMENT: '/settings/payment',
  SUBSCRIPTION_STATUS: '/settings/subscription-status',
  SUCCESS_PAGE: '/success-page',
  TREASURE_HUNT: `${SUBACTIVITY_BASE_URL}/treasure-hunt`,
  TREASURE_HUNT_COLLECTIONS: `${SUBACTIVITY_BASE_URL}/treasure-hunt/:groupId`,
  TREASURE_HUNT_LEVELS: `${SUBACTIVITY_BASE_URL}/treasure-hunt/:groupId/collections/:collectionId`,
  WHEEL_OF_FORTUNE: `${ACTIVITY_BASE_URL}/wheel-of-fortune`,
  WHEEL_OF_FORTUNE_LEVELS: `${ACTIVITY_BASE_URL}/wheel-of-fortune/:collectionId`,

  api: {
    ACTIVITIES: '/activities/:activityGroupKey',
    ACTIVITY_GROUPS: '/activity-groups',
    ARTICULATION_GYMNASTICS: '/articulation-gymnastics',
    ARTICULATION_GYMNASTICS_COLLECTIONS_ID_LEVELS:
      '/articulation-gymnastics/:collectionId/levels',
    AUTHENTICATE_FORGOT_PASSWORD: '/auth/forgot-password',
    AUTHENTICATE_HEALTH: '/auth/health',
    AUTHENTICATE_LOGIN: '/auth/login',
    AUTHENTICATE_FACEBOOK_LOGIN: '/auth/facebook-login',
    AUTHENTICATE_GOOGLE_LOGIN: '/auth/google-login',
    AUTHENTICATE_PAYMENT: '/auth/payment',
    AUTHENTICATE_REGISTER_CLIENT: '/auth/register/client',
    AUTHENTICATE_REGISTER_SPEECH_THERAPIST: '/auth/register/speech-therapist',
    AUTHENTICATE_RESET_PASSWORD: '/auth/reset-password',
    AUTHENTICATE_RESOLVE_USERS: '/auth/resolve-users',
    AUTHENTICATE_UPDATE_INFO: '/auth/update-info',
    CLIMB_STEP_THREE: '/climb-step-three',
    CLIMB_STEP_THREE_COLLECTIONS_ID_LEVELS:
      '/climb-step-three/:collectionId/levels',
    CLIMB_STEP_THREE_EXTRA: '/climb-step-three-extra',
    CLIMB_STEP_THREE_EXTRA_COLLECTIONS_ID_LEVELS:
      '/climb-step-three-extra/:collectionId/levels',
    CLIMB_STEP_TWO: '/climb-step-two',
    CLIMB_STEP_TWO_COLLECTIONS_ID_LEVELS:
      '/climb-step-two/:collectionId/levels',
    CLIMB_STEP_TWO_EXTRA: '/climb-step-two-extra',
    CLIMB_STEP_TWO_EXTRA_COLLECTIONS_ID_LEVELS:
      '/climb-step-two-extra/:collectionId/levels',
    CLIMB_STEP_ONE: '/climb-step-one',
    CLIMB_STEP_ONE_COLLECTIONS_ID_LEVELS:
      '/climb-step-one/:collectionId/levels',
    CLIMB_STEP_ONE_EXTRA: '/climb-step-one-extra',
    CLIMB_STEP_ONE_EXTRA_COLLECTIONS_ID_LEVELS:
      '/climb-step-one-extra/:collectionId/levels',
    COUPONS_ACTIVATE: '/coupons/activate',
    COUPONS_STATUS: '/coupons/status',
    DAILY_MAP_TEMPLATES: '/daily-map-templates',
    DAILY_MAP: '/daily-map',
    DAILY_MAPS: '/daily-maps',
    DAILY_MAPS_ID: '/daily-maps/:dailyMapId',
    DAILY_MAPS_ID_FEEDBACK: '/daily-maps/:dailyMapId/feedback',
    DAILY_MAPS_LEVELS: '/daily-maps/levels',
    DAILY_MAP_COLLECTION_VERIFICATION: '/daily-maps/verify-collection',
    DISCOVER_STEP_THREE: '/discover-step-three',
    DISCOVER_STEP_THREE_COLLECTIONS_ID_LEVELS:
      '/discover-step-three/:collectionId/levels',
    DISCOVER_STEP_THREE_EXTRA: '/discover-step-three-extra',
    DISCOVER_STEP_THREE_EXTRA_COLLECTIONS_ID_LEVELS:
      '/discover-step-three-extra/:collectionId/levels',
    DISCOVER_STEP_TWO: '/discover-step-two',
    DISCOVER_STEP_TWO_COLLECTIONS_ID_LEVELS:
      '/discover-step-two/:collectionId/levels',
    DISCOVER_STEP_TWO_EXTRA: '/discover-step-two-extra',
    DISCOVER_STEP_TWO_EXTRA_COLLECTIONS_ID_LEVELS:
      '/discover-step-two-extra/:collectionId/levels',
    DISCOVER_STEP_ONE: '/discover-step-one',
    DISCOVER_STEP_ONE_COLLECTIONS_ID_LEVELS:
      '/discover-step-one/:collectionId/levels',
    DISCOVER_STEP_ONE_EXTRA: '/discover-step-one-extra',
    DISCOVER_STEP_ONE_EXTRA_COLLECTIONS_ID_LEVELS:
      '/discover-step-one-extra/:collectionId/levels',
    FEEDBACK: '/feedback',
    FIND_WORDS_STEP_THREE: '/find-words-step-three',
    FIND_WORDS_STEP_THREE_COLLECTIONS_ID_LEVELS:
      '/find-words-step-three/:collectionId/levels',
    FIND_WORDS_STEP_THREE_EXTRA: '/find-words-step-three-extra',
    FIND_WORDS_STEP_THREE_EXTRA_COLLECTIONS_ID_LEVELS:
      '/find-words-step-three-extra/:collectionId/levels',
    FIND_WORDS_STEP_TWO: '/find-words-step-two',
    FIND_WORDS_STEP_TWO_COLLECTIONS_ID_LEVELS:
      '/find-words-step-two/:collectionId/levels',
    FIND_WORDS_STEP_TWO_EXTRA: '/find-words-step-two-extra',
    FIND_WORDS_STEP_TWO_EXTRA_COLLECTIONS_ID_LEVELS:
      '/find-words-step-two-extra/:collectionId/levels',
    FIND_WORDS_STEP_ONE: '/find-words-step-one',
    FIND_WORDS_STEP_ONE_COLLECTIONS_ID_LEVELS:
      '/find-words-step-one/:collectionId/levels',
    FIND_WORDS_STEP_ONE_EXTRA: '/find-words-step-one-extra',
    FIND_WORDS_STEP_ONE_EXTRA_COLLECTIONS_ID_LEVELS:
      '/find-words-step-one-extra/:collectionId/levels',
    FLASH_CARDS_STEP_THREE: '/flash-cards-step-three',
    FLASH_CARDS_STEP_THREE_COLLECTIONS_ID_LEVELS:
      '/flash-cards-step-three/:collectionId/levels',
    FLASH_CARDS_STEP_TWO: '/flash-cards-step-two',
    FLASH_CARDS_STEP_TWO_COLLECTIONS_ID_LEVELS:
      '/flash-cards-step-two/:collectionId/levels',
    FLASH_CARDS_STEP_ONE: '/flash-cards-step-one',
    FLASH_CARDS_STEP_ONE_COLLECTIONS_ID_LEVELS:
      '/flash-cards-step-one/:collectionId/levels',
    FLASH_CARDS_STEP_ONE_EXTRA: '/flash-cards-step-one-extra',
    FLASH_CARDS_STEP_ONE_EXTRA_COLLECTIONS_ID_LEVELS:
      '/flash-cards-step-one-extra/:collectionId/levels',
    FLASH_CARDS_STEP_TWO_EXTRA: '/flash-cards-step-two-extra',
    FLASH_CARDS_STEP_TWO_EXTRA_COLLECTIONS_ID_LEVELS:
      '/flash-cards-step-two-extra/:collectionId/levels',
    FLASH_CARDS_STEP_THREE_EXTRA: '/flash-cards-step-three-extra',
    FLASH_CARDS_STEP_THREE_EXTRA_COLLECTIONS_ID_LEVELS:
      '/flash-cards-step-three-extra/:collectionId/levels',
    HELP: '/help',
    INTERFACE_TEXT: '/interface-text',
    LICENSING: '/licensing',
    MAGIC_LINK: '/auth/magic-link',
    MEMORY_STEP_THREE: '/memory-step-three',
    MEMORY_STEP_THREE_ID_COLLECTIONS: '/memory-step-three/:groupId/collections',
    MEMORY_STEP_THREE_ID_COLLECTIONS_ID:
      '/memory-step-three/:groupId/collections/:collectionId',
    MEMORY_STEP_THREE_COLLECTIONS_ID_LEVELS:
      '/memory-step-three/collections/:collectionId/levels',
    MEMORY_STEP_THREE_EXTRA: '/memory-step-three-extra',
    MEMORY_STEP_THREE_EXTRA_ID_COLLECTIONS:
      '/memory-step-three-extra/:groupId/collections',
    MEMORY_STEP_THREE_EXTRA_ID_COLLECTIONS_ID:
      '/memory-step-three-extra/:groupId/collections/:collectionId',
    MEMORY_STEP_THREE_EXTRA_COLLECTIONS_ID_LEVELS:
      '/memory-step-three-extra/collections/:collectionId/levels',
    MEMORY_STEP_TWO: '/memory-step-two',
    MEMORY_STEP_TWO_ID_COLLECTIONS: '/memory-step-two/:groupId/collections',
    MEMORY_STEP_TWO_ID_COLLECTIONS_ID:
      '/memory-step-two/:groupId/collections/:collectionId',
    MEMORY_STEP_TWO_COLLECTIONS_ID_LEVELS:
      '/memory-step-two/collections/:collectionId/levels',
    MEMORY_STEP_TWO_EXTRA: '/memory-step-two-extra',
    MEMORY_STEP_TWO_EXTRA_ID_COLLECTIONS:
      '/memory-step-two-extra/:groupId/collections',
    MEMORY_STEP_TWO_EXTRA_ID_COLLECTIONS_ID:
      '/memory-step-two-extra/:groupId/collections/:collectionId',
    MEMORY_STEP_TWO_EXTRA_COLLECTIONS_ID_LEVELS:
      '/memory-step-two-extra/collections/:collectionId/levels',
    MEMORY_STEP_ONE: '/memory-step-one',
    MEMORY_STEP_ONE_ID_COLLECTIONS: '/memory-step-one/:groupId/collections',
    MEMORY_STEP_ONE_ID_COLLECTIONS_ID:
      '/memory-step-one/:groupId/collections/:collectionId',
    MEMORY_STEP_ONE_COLLECTIONS_ID_LEVELS:
      '/memory-step-one/collections/:collectionId/levels',
    MEMORY_STEP_ONE_EXTRA: '/memory-step-one-extra',
    MEMORY_STEP_ONE_EXTRA_ID_COLLECTIONS:
      '/memory-step-one-extra/:groupId/collections',
    MEMORY_STEP_ONE_EXTRA_ID_COLLECTIONS_ID:
      '/memory-step-one-extra/:groupId/collections/:collectionId',
    MEMORY_STEP_ONE_EXTRA_COLLECTIONS_ID_LEVELS:
      '/memory-step-one-extra/collections/:collectionId/levels',
    MOBILE_USERS_INVITE: '/mobile-users/invite',
    PAINTING_GROUPS: '/painting',
    PAINTING_ID_COLLECTIONS: '/painting/:groupId/collections',
    PAINTING_COLLECTIONS_ID_LEVELS:
      '/painting/collections/:collectionId/levels',
    PARROT_PARTS: '/parrot-parts',
    PARROT_PARTS_CHEAP: '/parrot-parts/cheap',
    PARROT_PARTS_BUY_BY_ID: '/parrot-parts/:parrotPartId/buy',
    PARROT_PARTS_BY_CATEGORY_LABEL: '/parrot-parts/:categoryLabel',
    PARROT_PARTS_OWNED: '/parrot-parts/owned',
    PARROT_SELECTED_PARTS_REMOVE_BY_ID:
      '/parrot-selected-parts/:parrotPartId/remove',
    PAYMENT_BANK: '/payments/bank/payment',
    PAYMENT_BANK_EMAIL: '/payments/bank/send-email',
    PAYMENT_CANCEL: '/payment/cancel',
    PAYMENT_PROVIDERS: '/payment/providers',
    READ_AND_THINK: '/read-and-think',
    READ_AND_THINK_COLLECTIONS_ID_BONUS_LEVELS: `/read-and-think/:collectionId/bonus-levels`,
    READ_AND_THINK_COLLECTIONS_ID_LEVELS:
      '/read-and-think/:collectionId/levels',
    READ_TOGETHER: '/read-together',
    READ_TOGETHER_COLLECTIONS_ID_BONUS_LEVELS:
      '/read-together/:collectionId/bonus-levels',
    READ_TOGETHER_COLLECTIONS_ID_LEVELS: '/read-together/:collectionId/levels',
    REPLENISHMENT: '/replenishment',
    REPLENISHMENT_COLLECTIONS_ID_LEVELS: '/replenishment/:collectionId/levels',
    SPEECH_THERAPIST_INVITE_USER: '/speech-therapists/invite-user',
    SPEECH_SOUNDS: '/speech-sounds',
    SPEECH_SOUNDS_ID: '/speech-sounds/:speechSoundId',
    STUDENTS: '/students',
    STUDENTS_ID: '/students/:studentId',
    STUDENTS_ID_NOTES: '/students/:studentId/notes',
    STUDENTS_ID_NOTES_ID: '/students/:studentId/notes/:noteId',
    STUDENTS_UNLOCK_COLLECTION: '/students/unlock-collection',
    SUBACTIVITIES_ACTIVITY_ID: '/subactivities/:activityId',
    SUBSCRIPTIONS: '/subscriptions',
    SUBSCRIPTIONS_CANCEL: '/subscriptions/cancel',
    SUBSCRIPTIONS_CHECK: '/subscriptions/check',
    SUBSCRIPTIONS_DISMISS_TRIAL_EXPIRATION_ALERT:
      '/subscriptions/dismiss-trial-expiration-alert',
    SUBSCRIPTIONS_STATUS: '/subscriptions/status',
    TREASURE_HUNT_GROUPS: '/treasure-hunt',
    TREASURE_HUNT_GROUP_ID_COLLECTIONS: '/treasure-hunt/:groupId/collections',
    TREASURE_HUNT_COLLECTIONS_ID_LEVELS:
      '/treasure-hunt/collections/:collectionId/levels',
    USERS_ME: '/users/me',
    WALLET: '/wallet',
    WHEEL_OF_FORTUNE: '/wheel-of-fortune',
    WHEEL_OF_FORTUNE_COLLECTIONS_ID_LEVELS:
      '/wheel-of-fortune/:collectionId/levels',
  },

  landingPage: {
    PRIVACY_STATEMENT: 'https://www.kokolingo.hr/izjava-o-privatnosti',
    TERMS: 'https://www.kokolingo.hr/uvjeti-kupnje',
  },

  build: (path, ...params) => {
    params.reverse();
    return path.replace(/(:\w+)/g, () => params.pop());
  },

  // eg. params { param1: valueParam1, param2: 10, .... }
  buildQuery: (path, params) => {
    const validParamEntries = Object.entries(params).filter(
      ([, paramValue]) => paramValue !== null && paramValue !== undefined
    );

    if (!validParamEntries.length) {
      return path;
    }

    const queryParams = new URLSearchParams(
      Object.fromEntries(validParamEntries)
    );

    return `${path}?${queryParams}`;
  },
};
