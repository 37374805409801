import React, { memo, useCallback, useEffect } from 'react';

import {
  Form,
  InfoField,
  Input,
  Page,
  PageLoader,
  Title,
} from 'src/components/common';
import { useIntlMessages } from 'src/hooks';

import { useDispatch, useSelector } from 'react-redux';
import { notifications, paths } from 'src/constants';
import { actions, selectors } from 'src/store';

import { useHistory } from 'react-router';
import validationSchema from './validationSchema';

const License = () => {
  const messages = useIntlMessages();
  const dispatch = useDispatch();
  const history = useHistory();
  const license = useSelector((state) => selectors.licensing.getLicense(state));
  const hasLoaded = useSelector((state) =>
    selectors.licensing.getHasLoaded(state)
  );

  useEffect(() => {
    dispatch(actions.licensing.getLicense());
  }, [dispatch]);

  const onSubmit = useCallback(
    (data) => {
      dispatch(actions.licensing.activateLicense(data))
        .then(() =>
          dispatch(
            actions.notifications.showNotification(
              messages.notification.licenseActivatedSuccessfully,
              notifications.type.SUCCESS,
              notifications.duration.SHORT
            )
          )
        )
        .then(() => history.push(paths.SPEECH_THERAPIST))
        .catch(() =>
          dispatch(
            actions.notifications.showNotification(
              messages.error.feedback.somethingWentWrong,
              notifications.type.ERROR,
              notifications.duration.SHORT
            )
          )
        );
    },
    [
      dispatch,
      history,
      messages.error.feedback.somethingWentWrong,
      messages.notification.licenseActivatedSuccessfully,
    ]
  );

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <Page containerClassName="coupon-settings-container">
      <Page.Header>
        <Title text={messages.page.licence.title} />
      </Page.Header>

      <Page.Body>
        {license ? (
          <InfoField
            label={messages.label.yourCoupon}
            value={license.activationKey}
          />
        ) : (
          <Form
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            submitBtnLabel={messages.button.activateLicense}
            formClassName="coupon-form"
          >
            <Input label={messages.label.enterLicense} name="activationKey" />
          </Form>
        )}
      </Page.Body>
    </Page>
  );
};

export default memo(License);
