import { actions, payment } from 'src/constants';
import { common, date } from 'src/utils';

const PROMO_POINTS_COUNT = 20;

const initialState = {
  data: {},
  hasLoaded: false,
  isLoading: false,
  isSubmitting: false,
  showTrialEndedModal: false,
  initialPoints: null,
  showPromoModal: false,
};

const actionMap = {
  [actions.COUPONS_ACTIVATE_SUCCESS]: (state, { result: { data } }) => ({
    ...state,
    data: {
      ...state.data,
      expireDate: common.getDateFromNow(data.subscriptionDurationInDays),
      isTrial: false,
      subscription: data.subscription,
      isSubscription: true,
      daysLeft: data.subscriptionDurationInDays,
      subscriptionExpirationDate: date
        .addDays(
          new Date(common.getDateFromNow(data.subscriptionDurationInDays)),
          1
        )
        .toISOString(),
    },
  }),

  [actions.LICENSE_ACTIVATE_POST_SUCCESS]: (state, { result: { data } }) => ({
    ...state,
    data: {
      ...state.data,
      expireDate: data.expirationDate,
      isTrial: false,
      subscription: data.subscriptionKey,
      isSubscription: true,
      subscriptionExpirationDate: data.expirationDate,
    },
  }),

  [actions.SUBSCRIPTION_STATUS_GET_REQUEST]: (state) => ({
    ...state,
    data: state.data || initialState.data,
    hasLoaded: false,
    isLoading: true,
  }),

  [actions.FEEDBACK_UPDATE_SUCCESS]: (state, { result: { data } }) => ({
    ...state,
    data: {
      ...(state.data || initialState.data),
      ...data.subscriptionStatus,
    },
    subscriptionCheckFailed:
      // Trial must be defined and falsy
      !data.subscriptionStatus.isTrial &&
      data.subscriptionStatus.isTrial !== undefined,
    showTrialEndedModal:
      // Show only first time
      !state.subscriptionCheckFailed &&
      // Trial must be defined and falsy
      !data.subscriptionStatus.isTrial &&
      data.subscriptionStatus.isTrial !== undefined,
    showPromoModal:
      state.initialPoints &&
      state.initialPoints - data.subscriptionStatus.pointsLeft ===
        PROMO_POINTS_COUNT,
  }),

  [actions.SUBSCRIPTION_STATUS_GET_SUCCESS]: (state, { result: { data } }) => ({
    ...state,
    data,
    subscriptionCheckFailed: !(data.isTrial || data.daysLeft > 0),
    // First time check fails show modal
    showTrialEndedModal: !state.subscriptionCheckFailed
      ? !(data.isTrial || data.daysLeft > 0)
      : state.showTrialEndedModal,
    hasLoaded: true,
    isLoading: false,
    initialPoints:
      // set initialPoints only first time status is fetch
      state.initialPoints === null ? data.pointsLeft : state.initialPoints,
  }),

  [actions.SUBSCRIPTION_STATUS_GET_FAILURE]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: false,
  }),

  [actions.SET_SHOW_TRIAL_ENDED_MODAL]: (state, { payload }) => ({
    ...state,
    showTrialEndedModal: payload,
  }),

  [actions.SET_SHOW_PROMO_MODAL]: (state, { payload }) => ({
    ...state,
    showPromoModal: payload,
  }),

  [actions.PAYMENT_CANCEL_SUCCESS]: (state) => ({
    ...state,
    data: {
      ...state.data,
      lastTransactionStatus: payment.PAYMENT_STATUS.CANCELED,
    },
  }),
};

export default (state = initialState, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
