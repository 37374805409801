import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { paths } from 'src/constants';
import { selectors } from 'src/store';
import { useScript } from 'src/hooks';

import navbarHooks from './hooks';
import navbarUtils from './utils';
import SettingsAction from '../SettingsAction';
import Badge from '../../../Badge';
import Localize from '../../../Localize';
import Tooltip from '../../../Tooltip';
import { navbarItemNames } from '../../constants';

const AddUserButton = () => (
  <Badge iconName="user" type="success" onClick={() => {}} />
);

const LogoutButtonNavbar = () => {
  const { logout } = navbarHooks.useLogout();

  return <Badge iconName="logout" type="danger" onClick={logout} />;
};

const BackButtonNavbar = () => {
  const navigateBack = navbarHooks.useNavigateBackForButton();

  return <Badge iconName="back" onClick={navigateBack} />;
};
const ShopButton = () => {
  const history = useHistory();

  return (
    <>
      <Badge
        iconName="shop"
        className="shop-badge"
        onClick={() => history.push(paths.SHOP)}
        tooltip="shop"
      />
      <Tooltip id="shop">
        <Localize id="tooltip.shop" />
      </Tooltip>
    </>
  );
};

const MapButton = () => {
  const history = useHistory();
  const student = useSelector(selectors.authentication.getStudent);
  const user = useSelector(selectors.authentication.getUser);

  return (
    <Badge
      iconName="map"
      type={student.SpeechSoundId ? 'blue' : 'neutral'}
      onClick={
        student.SpeechSoundId
          ? () =>
              history.push(paths.buildQuery(paths.MAP, { userId: user?.id }))
          : undefined
      }
    />
  );
};
const HelpButtonNavbar = () => {
  const history = useHistory();
  const location = useLocation();
  const { helpKey } = navbarUtils.getNavbarItems(location.pathname);

  return (
    <Badge
      iconName="questionmark"
      type={
        window.location.pathname !== paths.SPEECH_THERAPIST
          ? 'primary'
          : 'success'
      }
      onClick={() =>
        history.push(`${paths.HELP}${helpKey ? `?key=${helpKey}` : ''}`)
      }
    />
  );
};

const LicenseButton = () => {
  const history = useHistory();

  return (
    <>
      <Tooltip id="license">
        <Localize id="tooltip.license" />
      </Tooltip>
      <Badge
        iconName="card"
        type="primary"
        onClick={() => history.push(paths.LICENSE)}
        tooltip="license"
      />
    </>
  );
};

const fastSpringScriptConfig = {
  src:
    'https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.9.2/fastspring-builder.min.js',
  id: 'fsc-api',
  type: 'text/javascript',
  'data-storefront': 'kokolingo.onfastspring.com/slps-store',
};

const SLPStoreButton = () => {
  const { scriptLoaded } = useScript(fastSpringScriptConfig);

  return scriptLoaded ? (
    <>
      <Tooltip id="buyOutside">
        <Localize id="tooltip.buyOutside" />
      </Tooltip>
      <Badge
        iconName="cart"
        type="primary"
        onClick={() =>
          window.open('https://kokolingo.onfastspring.com/slps-store', '_blank')
        }
        tooltip="buyOutside"
      />
    </>
  ) : null;
};

const SpeechTherapistBackButton = () => {
  const history = useHistory();

  return (
    <Badge
      type="primary"
      iconName="back"
      onClick={() => history.push(paths.SPEECH_THERAPIST)}
    />
  );
};

const NAVBAR_BUTTONS = {
  [navbarItemNames.ADD_USER]: AddUserButton,
  [navbarItemNames.LOGOUT]: LogoutButtonNavbar,
  [navbarItemNames.BACK]: BackButtonNavbar,
  [navbarItemNames.SHOP]: ShopButton,
  [navbarItemNames.MAP]: MapButton,
  [navbarItemNames.HELP]: HelpButtonNavbar,
  [navbarItemNames.SETTINGS]: SettingsAction,
  [navbarItemNames.LICENSE]: LicenseButton,
  [navbarItemNames.FASTSPRING_WEB_STORE]: SLPStoreButton,
  [navbarItemNames.SPEECH_THERAPIST_BACK]: SpeechTherapistBackButton,
};

export default { NAVBAR_BUTTONS };
