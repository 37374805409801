import PropTypes from 'prop-types';
import React, { memo, useCallback, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { actions, selectors } from 'src/store';
import { notifications, paths, payment } from 'src/constants';
import { subscriptions } from 'src/utils';
import { useIntlMessages, useQuery } from 'src/hooks';

import { Button, Dialog, Page, PageLoader, Title } from 'src/components/common';

import InfoFields from './components/InfoFields';

import './index.scss';

const SubscriptionStatus = ({
  cancelSubscription,
  hasLoaded,
  showNotification,
  subscriptionStatus,
  user,
}) => {
  const history = useHistory();
  const messages = useIntlMessages();
  const query = useQuery();

  const [showDialog, setShowDialog] = useState(false);

  const paymentStatus = query.get('status');

  const handleOnClick = useCallback(() => {
    if (subscriptionStatus.daysLeft > 0 && !subscriptionStatus.isTrial) {
      showNotification(
        subscriptions.getSubscriptionSelectErrorMessage(messages),
        notifications.type.ERROR,
        notifications.duration.SHORT
      );
      return;
    }

    history.push(paths.SUBSCRIPTIONS);
  }, [
    history,
    messages,
    showNotification,
    subscriptionStatus.daysLeft,
    subscriptionStatus.isTrial,
  ]);

  const cancelTrial = useCallback(() => {
    if (!user.recurringRefId) {
      return;
    }

    cancelSubscription();

    setShowDialog(false);

    showNotification(
      messages.notification.automaticChargeDeactivated,
      notifications.type.INFO,
      notifications.duration.SHORT
    );
  }, [cancelSubscription, messages, showNotification, user.recurringRefId]);

  const noActivePaidSubscription =
    !subscriptionStatus.isSubscription &&
    subscriptionStatus.lastTransactionStatus !==
      payment.PAYMENT_STATUS.IN_PROGRESS;

  if (!hasLoaded) {
    return <PageLoader />;
  }

  if (paymentStatus === 'canceled') {
    query.delete('status');
    history.replace({ search: query.toString() });

    showNotification(
      messages.notification.paymentCanceled,
      notifications.type.ERROR,
      notifications.duration.SHORT
    );
  }

  const content = (
    <Page containerClassName="subscription-status-page">
      <Page.Header>
        <Title text={messages.page.subscription.title} />
      </Page.Header>
      <Page.Body>
        <InfoFields
          cancelTrial={() => setShowDialog(true)}
          subscriptionStatusData={subscriptions.getSubscriptionStatusData(
            subscriptionStatus,
            messages
          )}
          showSubscriptionCancelLink={!!user.recurringRefId}
          onClick={handleOnClick}
          lastTransactionStatus={subscriptionStatus.lastTransactionStatus}
        />
        <Dialog
          showDialog={showDialog}
          onPrimaryBtnClick={cancelTrial}
          onSecondaryBtnClick={() => setShowDialog(false)}
          type="error"
          title={messages.dialog.automaticChargeDisable.title}
          text={messages.dialog.automaticChargeDisable.description}
          primaryBtnLabel={messages.button.yes}
          secondaryBtnLabel={messages.button.no}
        />
      </Page.Body>
      <Page.Footer>
        {process.env.REACT_APP_LANGUAGE === 'en-us' && (
          <Button
            variant="primary"
            className="button-container__button"
            label={messages.button.fastSpring.account}
            onClick={() =>
              window.open(
                'https://kokolingo.onfastspring.com/account',
                '_blank'
              )
            }
          />
        )}
        {noActivePaidSubscription && (
          <Button
            variant="register"
            className="button-container__button"
            label={messages.button.selectSubscription}
            onClick={handleOnClick}
          />
        )}
      </Page.Footer>
    </Page>
  );

  return content;
};

SubscriptionStatus.propTypes = {
  cancelSubscription: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  subscriptionStatus: PropTypes.shape({
    isTrial: PropTypes.bool,
    isSubscription: PropTypes.bool,
    daysLeft: PropTypes.number,
    subscription: PropTypes.string,
    expireDate: PropTypes.string,
    lastTransactionStatus: PropTypes.string,
    subscriptionExpirationDate: PropTypes.string,
  }).isRequired,
  showNotification: PropTypes.func.isRequired,
  user: PropTypes.shape({
    recurringRefId: PropTypes.string,
    subscriptionKey: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.subscriptionStatus.getHasLoaded(state),
  subscriptionStatus: selectors.subscriptionStatus.getSubscriptionStatus(state),
  user: selectors.authentication.getUser(state),
});

const mapDispatchToProps = {
  ...actions.notifications,
  ...actions.subscriptions,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(SubscriptionStatus);
