import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Localize } from '@kokolingo/localisation';

import { paths } from 'src/constants';
import { actions, selectors } from 'src/store';
import { useQuery } from 'src/hooks';

import Modal from '../Modal/Modal';
import Button from '../Button';

import './index.scss';

const ModalPromo = ({
  showPromoModal,
  setShowPromoModal,
  specialOfferSubscriptions,
}) => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  const close = useCallback(() => {
    setShowPromoModal(false);
  }, [setShowPromoModal]);

  useEffect(() => {
    if (!query.get('promoModal') && showPromoModal) {
      window.location.hash = 'promoModal';
    }
  }, [location, showPromoModal, query]);

  return (
    <Modal
      show={showPromoModal && specialOfferSubscriptions.length > 0}
      onClose={close}
      className="promo-modal"
    >
      <Modal.Title>
        <Localize id="dialog.promoTitle" />
      </Modal.Title>
      <br />
      <Modal.Description>
        <Localize id="dialog.promoDescription" />
      </Modal.Description>
      <br />
      <Button
        variant="register"
        collapsed
        onClick={() => {
          close();
          history.push(paths.SUBSCRIPTIONS);
        }}
      >
        <Localize id="dialog.promoCTA" />
      </Button>
    </Modal>
  );
};

ModalPromo.propTypes = {
  showPromoModal: PropTypes.bool.isRequired,
  setShowPromoModal: PropTypes.func.isRequired,
  specialOfferSubscriptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state) => ({
  showPromoModal: selectors.subscriptionStatus.getShowPromoModal(state),
  specialOfferSubscriptions: selectors.subscriptions.getSpecialOfferSubscriptions(
    state
  ),
});
const mapDispatchToProps = {
  ...actions.subscriptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPromo);
