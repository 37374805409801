import { createSelector } from 'reselect';

const getLicense = createSelector(
  (state) => state.licensing.data,
  (license = {}) => license
);

const getHasLoaded = createSelector(
  (state) => state.licensing.hasLoaded,
  (hasLoaded) => hasLoaded
);

const getIsLoading = createSelector(
  (state) => state.licensing.isLoading,
  (isLoading) => isLoading
);

export default {
  getLicense,
  getHasLoaded,
  getIsLoading,
};
